import React from 'react'
import { motion } from "framer-motion";
import Button from '@mui/material/Button';
import { Paper, Grid} from '@mui/material'
import { SocialIcon } from 'react-social-icons';
export function Contact_Me() {

    const UpdatingContainer = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      
    }
    const TextStyle={
      fontSize:'3vw',
      color:'#00897b',
      fontWeight:'bold'
    
      
    }
    
    const LoadingDot = {
      width: "2vw",
      height: '2vw',
      backgroundColor: "#00897b",
      borderRadius: "50%"
    };
    
    
    const LoadingContainer = {
      width: '8vw',
      display: "flex",
      justifyContent: "space-around"
    };
    
    const ContainerVariants = {
      initial: {
        transition: {
          staggerChildren: 0.2
        }
      },
      animate: {
        transition: {
          staggerChildren: 0.2
        }
      }
    };
    
    const DotVariants = {
      initial: {
        y: "0%"
      },
      animate: {
        y: "100%"
      }
    };
    
    const DotTransition = {
      duration: 0.5,
      yoyo: Infinity,
      ease: "easeInOut"
    };
  return (
    <motion.div className="contact_me_container"
    animate={{
      rotateY: 180,
    transition: { duration: 0.5 }
    }}>
    <Paper sx={{
      minHeight: '80vh',
      width: {
        xs: '80vw',
        sm: '60vw',
        md: '70vw',
        lg: '70vw',
        xl: '70vw'

      },
      padding: {
        xs: '4%',
        sm: '3%',
        md: '2%',
        lg: '2%',
        xl: '2%'
      },
      margin: {
        xs: '4%',
        sm: '3%',
        md: '2%',
        lg: '2%',
        xl: '2%'
      },
      display: 'flex',
      justifyContent: 'center',
      background: 'rgba(255, 255, 255, 0.2)',
      boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
      backdropFilter: 'blur(2px)',
      border: '1px solid rgba(255, 255, 255, 0.3)',
      borderRadius: '16px'
    }}
      elevation={5}
    >
      
      <motion.div className='Contact_Me'
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
          rotateY: 180
        }}
        transition={{
          duration: 0.5
        }}>

      <div className='contact_me_content'>
      <div className='neon_hr_contact_me'>
      <motion.div className='contact_me_intro_container'
      initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{
                      duration: 0.7,
                      delay: 0.6,
                      ease: [0, 0.71, 0.2, 1.01]
                    }}
      >
        <div className='contact_me_intro'><span className='contact_me_highlights pulsate' >CONTACT ME</span></div>
      </motion.div>
      </div>
       
      <motion.div className='contact_me_info'
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.7,
        delay: 0.8,
        ease: [0, 0.71, 0.2, 1.01]
      }} >
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className='contact_info_container'>
                <div className='contact_email'>
                  <label className='contact_info label'>Email:</label>
                  <p className='contact_info'>ericromero044@outlook.com</p>
                </div>
                <div className='contact_number'>
                <label className='contact_info label'>Mobile number:</label>
                  <p className='contact_info'><span className='contact_parenthesis'>(</span>+63<span className='contact_parenthesis'>)</span> 939 506 0116</p>
                </div>
    
              </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className='socials_container'>

                <div className='social_icon_container'>
                  <SocialIcon className='social_icons' url="https://www.facebook.com/eric.oremor" bgColor="#FBFCFC" fgColor="#00897b" />
                  <SocialIcon className='social_icons' url="https://twitter.com/E_Oremor"  bgColor="#FBFCFC" fgColor="#00897b"/>
                  <SocialIcon className='social_icons' url="https://github.com/ericromero044" bgColor="#FBFCFC" fgColor="#00897b" />
                  <SocialIcon className='social_icons' url="https://www.linkedin.com/in/eric-romero-0aa164245/"  bgColor="#FBFCFC" fgColor="#00897b"/>
                </div>

                  <div className='portfolio_quality'>
                  <a href='https://web.dev/measure/?url=https%3A%2F%2Feric.topspot.link' target='_blank'>
                <button className='portfolio_test'>
                    Portfolio Page Quality
                </button>
                </a>
                <a href='https://pagespeed.web.dev/report?url=https%3A%2F%2Feric.topspot.link%2F' target='_blank'>
                <button className='portfolio_test'>
                    Portfolio Speed Test
                </button>
                </a>
                  </div>

                </div>
          </Grid>
        </Grid>
      </motion.div>

      <motion.div className='contact_me_forms_container' initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.7,
          delay: 0.5,
          ease: [0, 0.71, 0.2, 1.01]
        }}>
      <div className='contact_me_forms'>
      <div className='send_email'>EMAIL FIELD<span style={{fontSize:'1rem'}}>&#128071;</span></div>
      <div>
           <motion.div className='updating-container' style={UpdatingContainer}>
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.7,
          delay: 0.5,
          ease: [0, 0.71, 0.2, 1.01]
        }}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>

<h1 style={TextStyle}>UNDER DEVELOPMENT</h1>

      <motion.div
        style={LoadingContainer}
        variants={ContainerVariants}
        initial="initial"
        animate="animate"
      >
        <motion.span
          style={LoadingDot}
          variants={DotVariants}
          transition={DotTransition}
        />
        <motion.span
          style={LoadingDot}
          variants={DotVariants}
          transition={DotTransition}
        />
        <motion.span
          style={LoadingDot}
          variants={DotVariants}
          transition={DotTransition}
        />
      </motion.div>
      </motion.div>
    </motion.div>
      </div>
      </div>

       
      </motion.div>
      </div>



      
  
      
      </motion.div>
    

    </Paper>
    </motion.div>
  )
}

export default Contact_Me();
