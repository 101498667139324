import { motion } from "framer-motion";
import Button from '@mui/material/Button';
import { Paper, Grid } from '@mui/material'
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom'
import React, {useState} from 'react'


function About_Me() {
 const [expanded, setExpanded] = useState<string | false>(false)
  const handleChange = (isExpanded: boolean, panel:string) => {
    setExpanded(isExpanded ? panel: false)}
  
   const navigate = useNavigate()
   return (
    <motion.div className="about_me_container"
      animate={{
        rotateY: 180,

        transition: { duration: 0.5 }
      }}>
      <Paper sx={{

        minHeight: '80vh',
        width: {
          xs: '80vw',
          sm: '60vw',
          md: '50vw',
          lg: '50vw',
          xl: '50vw'

        },
        padding: {
          xs: '4%',
          sm: '3%',
          md: '2%',
          lg: '2%',
          xl: '2%'
        },
        margin: {
          xs: '4%',
          sm: '3%',
          md: '2%',
          lg: '2%',
          xl: '2%'
        },
        display: 'flex',
        justifyContent: 'center',
        background: 'rgba(0, 0, 0, 0.6)',
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        backdropFilter: 'blur(2px)',
        border: '1px solid rgba(255, 255, 255, 0.5)',
        borderRadius: '16px'
      }}
        elevation={5}
      >
        <motion.div className='About_Me'
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            rotateY: 180
          }}
          transition={{
            duration: 0.5
          }}
        >

          <div className='eric_bio_container'>
            <div className='neonText'>Who is Eric?
            </div>
            <div className='neon_hr'>
            </div>

            <Grid container sx={{
              padding: '3%'
            }}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className='eric_bio'>
                  <motion.div className="eric_bio_pic" initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{
                      duration: 0.7,
                      delay: 0.6,
                      ease: [0, 0.71, 0.2, 1.01]
                    }}>
                  </motion.div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className='eric_bio'>
                  <motion.div initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{
                      duration: 0.7,
                      delay: 0.7,
                      ease: [0, 0.71, 0.2, 1.01]
                    }}>
                    <motion.blockquote style={{
                      fontSize: '1rem',
                      fontFamily: 'Cambria, Cochin, Georgia, Times, "Times New Roman", serif'
                    }}>
                      <span className='eric_romero'> Eric Romero</span>  is a young <span className='frontEnd pulsate'>Front-End Developer</span> from Philippines.
                      Fresh student graduate from STI College, with an Associate Degree in Computer Technology.
                    </motion.blockquote>
                  </motion.div>
                </div>
              </Grid>
            </Grid>

            <motion.div className='accordion_container' initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.7,
                delay: 0.8,
                ease: [0, 0.71, 0.2, 1.01]
              }}>
              <Accordion expanded={expanded==='panel1'} onChange={(event, isExpanded) => handleChange(isExpanded,'panel1')}  sx={{
                background: 'none'
              }} elevation={0}>
                <AccordionSummary id='panel1 header' aria-controls='panel1 content' expandIcon={<ExpandMoreIcon style={{ color: 'aqua' }} />}>
                  <Typography sx={{
                    fontSize: '1rem',
                    color: 'white'
                  }}>Fun facts about <span style={{ color: 'aqua', fontSize: '1.1rem' }}>him</span>: <span className='emoji'>&#129504;</span></Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ul className='accordionList' >
                    <li className='accordionItems'>Eric likes to play <span className='accordionHighlight'>Rubik's cube</span>, various <span className='accordionHighlight'>puzzles,</span> and solving <span className='accordionHighlight'>logical problems</span>.</li>
                    <li className='accordionItems'>He is <span className='accordionHighlight'>artistic</span> and <span className='accordionHighlight'>creative</span>.</li>
                    <li className='accordionItems'>He is interested about <span className='accordionHighlight'>Technology</span>, <span className='accordionHighlight'>Astronomy</span> and <span className='accordionHighlight'>Science</span>.</li>
                  </ul>
                </AccordionDetails>
              </Accordion>
            </motion.div>

            <motion.div className='accordion_container' initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.7,
                delay: 0.8,
                ease: [0, 0.71, 0.2, 1.01]
              }}>
              <Accordion expanded={expanded==='panel2'} onChange={(event, isExpanded) => handleChange(isExpanded,'panel2')}  sx={{
                background: 'none'
              }} elevation={0}>
                <AccordionSummary id='panel2 header' aria-controls='panel2 content' expandIcon={<ExpandMoreIcon style={{ color: 'aqua' }} />}>
                  <Typography sx={{
                    fontSize: '1rem',
                    color: 'white'
                  }}>He is specialized on using these <span style={{ color: 'aqua', fontSize: '1.1rem' }}>technologies</span>: <span className='emoji'>&#128187;</span></Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}sx={{
              padding:'3%',
            }}>
  
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <div className='badge_container' >
                <motion.div className='badges' >
                  HTML5
                </motion.div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <div className='badge_container' >
                <motion.div className='badges'>
                  CSS3
                </motion.div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <div className='badge_container' >
                <motion.div className='badges'>
                  BOOTSTRAP
                </motion.div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <div className='badge_container' >
                <motion.div className='badges'>
                  JAVASCRIPT(ES6)
                </motion.div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <div className='badge_container' >
                <motion.div className='badges'>
                  TYPESCRIPT
                </motion.div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <div className='badge_container' >
                <motion.div className='badges' style={{
                   background: 'rgba(0, 255, 255, 0.1)',
                }}>
                  REACTJS + MATERIAL UI
                </motion.div>
                </div>
              </Grid>
            </Grid>
                </AccordionDetails>
              </Accordion>
            </motion.div>
            
            <motion.div
              className='contact_him_container'
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.7,
                delay: 0.9,
                ease: [0, 0.71, 0.2, 1.01]
              }}>
              <div className='contact_him_title'>Do you want to know him more?</div>
              <div className='contact_him'>
              
                <button className='contactButton'
                  onClick={() => navigate('/contact_me')} 
                  >Contact Him</button>
              </div>
            </motion.div>
          </div>

        </motion.div>
      </Paper>
    </motion.div>
  )
}

export default About_Me