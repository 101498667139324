import { useNavigate } from 'react-router-dom'
import { motion } from "framer-motion";
import { Paper } from '@mui/material'


export function Main() {
  const navigate = useNavigate()
  return (
<div className='main_content_container'>
    <Paper sx={{
      minHeight: '80vh',
      width: {
        xs: '80vw',
        sm: '60vw',
        md: '50vw',
        lg: '50vw',
        xl: '50vw'

      },
      padding: {
        xs: '4%',
        sm: '3%',
        md: '2%',
        lg: '2%',
        xl: '2%'
      },
      margin: {
        xs: '4%',
        sm: '3%',
        md: '2%',
        lg: '2%',
        xl: '2%'
      },
      display: 'flex',
      justifyContent: 'center',
      background: 'rgba(255, 255, 255, 0.2)',
      boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
      backdropFilter: 'blur(2px)',
      border: '1px solid rgba(255, 255, 255, 0.3)',
      borderRadius: '16px'
    }}
      elevation={5}
    >

      <motion.div className='Main'

        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.7,
          delay: 0.5,
          ease: [0, 0.71, 0.2, 1.01]
        }}>
        <motion.div className="hero_text"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.7,
            delay: 0.8,
            ease: [0, 0.71, 0.2, 1.01]
          }}
        >
          <div className='Intro'>
            <h1 className='Name'>Eric Romero</h1>
            <div className='title_container'>
              <p className='Title'>And I'm a Front-End Developer</p>
            </div>

            <div className='hire_me_button_container'>

            <motion.button
             initial={{ opacity: 0, scale: 0.5 }}
             animate={{ opacity: 1, scale: 1 }}
             transition={{
               duration: 0.3,
               delay: 0.5,
               ease: [0, 0.71, 0.2, 1.01]
             }} className='hire_me_button'
                  onClick={() => navigate('/contact_me')} 
                  >Hire Me</motion.button>


            </div>
          </div>
        </motion.div>
      </motion.div>
    </Paper>
    </div>
  )
}

export default Main;