
import {Navigation} from './components/Navigation';
import './App.css';
import {Main} from './components/Main'
import {Contact_Me} from './components/Contact_Me'
import About_Me from './components/About_Me'
import { Routes, Route } from 'react-router-dom'
function App() {
  return (
    <div className='App'>
      <Navigation />
      <Routes>
            <Route path='/' element={<Main/>} />
            <Route path='/about_me' element={<About_Me/>} />
            <Route path='/contact_me' element={<Contact_Me/>} />
      </Routes>
    </div>
  )
}

export default App;
