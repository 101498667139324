import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { motion } from "framer-motion";
import Fingerprint from '@mui/icons-material/Fingerprint';
import {useNavigate} from 'react-router-dom'


export function Navigation() {
 const navigate = useNavigate()
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" elevation={0} style={{
                background: 'none',
            }}>
                <Toolbar sx={{
                    justifyContent: {
                        xs: 'center',
                        sm: 'end',
                        md: 'end',
                        lg: 'end',
                        xl: 'end'
                    }
                }}>
                    <motion.div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            padding: '1%',
                            borderRadius: '50%',
                            border: '1px solid white',
                            margin: '0'
                        }}
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        whileHover={{
                            scale: [null, 1.2, 1.3],
                            border: '0'
                        }}
                        transition={{ duration: 0.3 }}
                    >
                        
                        <IconButton onClick={() => navigate('about_me')}
                            size="large"
                            edge="end"
                            color="inherit"
                            aria-label="menu"
                            sx={{
                                margin: '0',
                                padding: '0',
                                border: '0',
                                color: 'white'
                            }}
                        >
                            <Fingerprint />
                        </IconButton>
                    </motion.div>
                </Toolbar>
            </AppBar>
        </Box>
    )
}

export default Navigation;